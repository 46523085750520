<template>
  <MainLayout>
    <div class="overflow-x-hidden overflow-y-auto">
      <router-link :to="getPath()" class="flex items-center py-2">
        <chevron-back width="14" height="14" /> &nbsp; Colleges
      </router-link>
      <div class="w-full ">
        <div class="py-5 min-h-max">
          <div
            class="flex items-center justify-between gap-5"
          >
            <div class="w-full flex items-center gap-5">
               <img class="w-[66px] rounded-full" src="https://static.vecteezy.com/system/resources/previews/017/172/099/non_2x/university-college-school-emblem-logo-design-vector.jpg" alt=""> 
                <h2 class="text-2xl font-bold">
                  {{ collegeData.School_Name }}
                </h2>
            </div>
          </div>
        </div>
        <div class="my-2">
          <div class="flex items-center space-x-2">
            <button
                :class="[
                  'rounded-full text-[var(--text475f4a)] px-4 py-1 cursor-pointer ',
                  selectedTab === i ? 'bg-[var(--style)] text-white' : 'bg-[var(--style-percent-13)]',
                ]"
                v-for="(tab, i) in tabs"
                :key="i"
                @click="updateTab(i)"
            >
              {{ tab }}
            </button>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col overflow-y-auto">
        <Welcome :collegeData="collegeData" v-if="selectedTab === 0" />
        <Overview
          :collegeData="collegeData"
          v-else-if="selectedTab === 1"
        />
        <Costs :collegeData="collegeData" v-else-if="selectedTab === 2" />
        <UGMajors
          :collegeData="collegeData"
          v-else-if="selectedTab === 3"
        />
        <Athlets
          :collegeData="collegeData"
          v-else-if="selectedTab === 4"
        />
        <Scholarship
          :collegeData="collegeData"
          v-else-if="selectedTab === 5"
        />
      </div>
    </div>
  </MainLayout>
</template>

<script>
//import collegeJSON from "../../assets/js/peterson/_2024_INUN_9250_9499.json";
import collegeJSON from "../../assets/js/peterson/UG_EXPENSE_ASGNS_2024_INUN_5250_10499.json";

import MainLayout from "../../layouts/MainLayout.vue";
import Welcome from "./Welcome.vue";
import Overview from "./Overview.vue";
import Costs from "./Costs.vue";
import UGMajors from "./UGMajors.vue";
import Athlets from "./Athlets.vue";
import Scholarship from "./Scholarship.vue";
import ChevronBack from "@/icons/chevronBack.vue";

import { useStudentDemo } from "../../store/StudentDemoStates";

export default {
  name: "CollegeProfile",
  components: {
    ChevronBack,
    MainLayout,
    Welcome,
    Overview,
    Costs,
    UGMajors,
    Athlets,
    Scholarship
  },
  props: ["leftNavigationBarProps"],
  data() {
    return {
      selectedTab: 0,
      tabs: [
        "Welcome",
        "Overview",
        "Costs",
        "Undergraduate Majors",
        "Athletics",
        "Scholarships"
      ],
      store:null
    };
  },
  computed: {
    collegeData() {
      return collegeJSON.find((el) => el.INUN_ID === this.$route.params.id);
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    updateTab(tab){
      const id = this.$route.params.id;
      this.selectedTab = tab;
      this.$router.push('/college/'+id+'?tab='+tab)
    },
    getPath() {
      return this.store?.isStudentDemo ? '/educator/studentDemo/researchDefault?tab=2' : '/researchDefault?tab=2';
    },
  },
  mounted(){
    const store = useStudentDemo()
    this.store = store
    const tab = this.$route.query.tab;
    this.selectedTab = tab? parseInt(tab) : 0;

    
  }
};
</script>

<style scoped>
 .tab-btn {
    @apply px-5 py-1.5 border border-gray-300 rounded-full w-max min-w-fit text-sm hover:bg-gray-200 ;
  }
.selected-tab {
  @apply bg-primary text-white;
}

.home-feed-student-default {
  align-items: flex-start;
  background-color: #f3f7f8;
  border: 1px none;
  display: flex;
  gap: 25px;
  height: 100vh;
  overflow: hidden;
  padding: 0 18px;
  position: relative;
  max-width: 1512px;
  width: 100%;
}

@media all and (max-width: 900px) {
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 982px;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 100vw !important;
  }
}

.broadcast-feed {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  height: 100%;
  width: 100%;
}

.overlap-HomeFeedStudentDefaultgroup1 {
  border-radius: 30px;
  position: relative;
  @apply w-full h-full overflow-scroll pb-60;
}
.frame-2342 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 30px;
  box-shadow: 0px 0px 15px #0000000d;

  gap: 10px;
  min-width: 100%;
  left: 0;
  padding: 50px 28px;
  @apply h-max relative;
}

.flex-cold {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  /* min-height: 1579px; */
  position: relative;
  width: 100%;
  height: 100vh;
}

.heading1 {
  @apply font-bold text-xl capitalize mb-2.5;
}
.paragraph {
  @apply text-base text-gray-700 mb-5;
}
</style>
