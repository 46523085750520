<template>
  <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
    <h3 class="text-xl font-medium text-black">Scholarships for State : <b>{{ collegeData.STATE_CODE }}</b></h3>
    <div class="p-4 h-full overflow-y-scroll max-h-[500px] relative">
      <div class="">
        <ul class="divide-y">
          <li class="font-bold uppercase text-[15px] p-3 grid grid-cols-4 gap-4  sticky -top-4 bg-white">
            <p>Name</p>
            <p>City</p>
            <p>State</p>
            <p>Amount</p>
          </li>
          <li v-for="item in curr_scholarships" :key="item" class="font-[500] text-[15px] p-3 grid grid-cols-4 gap-4">
            <p>{{ item.SFUND }}</p>
            <p>{{ item.CITY }}</p>
            <p>{{ item.ST }}</p>
            <p>{{item.HIAMNT}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import scholarships from '../../assets/js/scholarship/scholarships'
  export default {
    props:["collegeData"],
    data() {
      return {
        listItems: [
          { name: "Stamps Scholars Award", price: '23,456'}, 
          { name: "George C Awards", price: '23,456'}, 
          { name: "Diversity Award", price: '23,456'}, 
          { name: "Swim", price: '23,456'}, 
          { name: "Polo", price: '23,456'},
        ],
        title: "Scholarship"
      };
    },
    computed:{
      curr_scholarships(){
        return scholarships.find(el => this.collegeData.STATE_CODE.toLowerCase() === el.state_code.toLowerCase())?.json
      }
    }
  };
</script>