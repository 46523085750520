<template>
  <MainLayout>
    <div class="w-full h-full gap-5 flex">
      <BroadcastFeed :isParent="isParent" @clickParentControlButton="this.showWelcomeMessageToParent=true"/>
      <StudentPrefrences />
      <WelcomeMessage v-if="showWelcomeMessageToParent" @welcomeMessageClosed="welcomeMessageClosed" />
    </div>
  </MainLayout>
</template>

<script>
  import BroadcastFeed from "./BroadcastFeed.vue";
  import StudentPrefrences from "./StudentPrefrences.vue";
  import MainLayout from "../layouts/MainLayout.vue";
  import WelcomeMessage from './Parent/WelcomeMessage.vue';
  import {getAssociatedData} from '../AssociatedData.js';

  export default {
    name: "HomeFeedStudentDefault",
    components: {
      WelcomeMessage,
      BroadcastFeed,
      MainLayout,
      StudentPrefrences,
    },
    data() {
      return {
        isParent: false,
        showWelcomeMessageToParent: false,
        email: ''
      }
    },
    props: ["careerProfileFrameProps"],
    async mounted() {
      const assocData = await getAssociatedData();
      if(assocData?.roles?.length && assocData.roles.includes('parent')) {
        // user is parent, lets check local storage for welcome message
        this.isParent=true;
        if(assocData.email) {
          const existInLocalStorage = localStorage.getItem(`welcome_message_${assocData.email}`);
          this.email = assocData.email;
          if(!existInLocalStorage) {
            this.showWelcomeMessageToParent = true;
            // localStorage.setItem(`welcome_message_${assocData.email}`, 'true');
          } else {
            // this.showWelcomeMessageToParent = false;
          }
        }
      }
    },
    methods: {
      welcomeMessageClosed() {
        console.log("set")
        this.showWelcomeMessageToParent = false;
        if(this.email) {
          localStorage.setItem(`welcome_message_${this.email}`, 'true');
        }
      }
    }
  };
</script>
