<template>
  <div class="wrapper">
    <!-- Filters Section -->
    <div class="flex items-start flex-col justify-between pb-4 my-3 bg-white z-40">
      <div class="py-5 flex justify-end w-full">
        <button
            class="bg-white py-3 px-6 shadow-[0_0_10px_0_rgba(71,95,74,0.151)] text-[15px] text-[#259B35] ml-10 rounded-xl"
            @click.prevent="showSavedOccupation">
          Saved Occupations
        </button>
      </div>
      <div class="flex items-center gap-5 py-5 flex-wrap">
        <p class="text-gray-500 font-[15px]">Filters</p>
        <CustomSelect
          clearable
          :options="['All', '0 - $49k', '$50 - $74k', '$75k - $99k', '$100k - $149k', '$150k - $199k', 'Above $200k']"
          :placeholder="salaryPlaceholder" @select="handleSelect('salary', $event)" ref="salarySelect" />
        <CustomSelect clearable width="180px" :options="['All', ...careerClusters]" :placeholder="careerPlaceholder"
          @select="handleSelect('cluster', $event)" ref="carrerSelect" />
        <CustomSelect clearable :options="['All', '1', '2', '3', '4', '5']" @select="handleSelect('education', $event)"
          :placeholder="jobPlaceholder" ref="jobSelect" />
        <!-- <CustomSelect v-if="typeFormDataExists" clearable v-model="scoreFilter1" :options="['Realistic', 'Investigative', 'Artistic', 'Social', 'Enterprising', 'Conventional'].filter((e) => scoreFilter2 !== e && scoreFilter3 !== e)"  placeholder="1st"/>
        <CustomSelect v-if="typeFormDataExists" clearable v-model="scoreFilter2" :options="['Realistic', 'Investigative', 'Artistic', 'Social', 'Enterprising', 'Conventional'].filter((e) => scoreFilter1 !== e && scoreFilter3 !== e)"  placeholder="2nd"/>
        <CustomSelect v-if="typeFormDataExists" clearable v-model="scoreFilter3" :options="['Realistic', 'Investigative', 'Artistic', 'Social', 'Enterprising', 'Conventional'].filter((e) => scoreFilter1 !== e && scoreFilter2 !== e)"  placeholder="3rd"/> -->
<!--        <CheckBox v-model="bestFit" :weight="600">Suggested Best Fit</CheckBox>-->
      </div>
    </div>
    
    <!-- Table Section -->
    <div class="max-h-full h-full pt-0 pr-6 pb-6 pl-6 border border-gray-300 rounded-xl overflow-scroll">
      <pre class="hidden">
      {{ filteredData }}
    </pre>
      <table class="w-full">
        <thead>
          <tr>
            <th v-for="(col, index) in colNames" :key="index"
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pt-7 pb-4 sticky top-0 bg-white z-10">
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody v-if="filteredData.length > 0">
          <tr class="cursor-pointer" v-for="(el, i) in filteredData" :key="i"
            @click="navigateTo('/occupation/' + el['O*NET-SOC Code'] + '?tab=0')">
            <td v-tooltip:top="el.Title" class="text-black font-medium text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              <span class="inline-block cursor-pointer" @click.stop="changeIcon(i, el)">
                <img :src="savedOccupations === true ? bookmarkedIcon : isBookmarked[i] ? bookmarkedIcon : bookmarkIcon"
                  class="mr-2" />
              </span>
              {{ truncate(el["Title"], 25) }}
            </td>
            <td class="text-black font-medium text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ getSalary(el["O*NET-SOC Code"])?.A_MEDIAN }}
            </td>
            <td v-tooltip:top='el["Career Cluster"]' class="text-black font-medium text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ truncate(el["Career Cluster"], 25) }}
            </td>
            <td v-tooltip:top='el["Career Pathway"]' class="text-black font-medium text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ truncate(el["Career Pathway"], 25) }}
            </td>
            <td class="text-black font-medium text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5 tooltip"
              :data-tooltip="getJobZoneDescription(el['Job Zone'])">
              {{ truncate(el["Job Zone"], 25) }}
            </td>
            <td v-if="isCodePresent(el['O*NET-SOC Code'])"
              class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              <i class="fas fa-star" style="color: #e7d104"></i>
            </td>
            <td v-else class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              ---
            </td>
            <td class="text-black font-medium text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ el['careerInterest'] || '--' }}
            </td>
          </tr>
        </tbody>
        <div v-else-if="loading" class="w-full p-5 flex items-center justify-center font-semibold">Loading ...</div>
        <div v-else class="w-full p-5 flex items-center justify-center font-semibold">No Data Found !</div>
      </table>
    </div>
    <div class="w-full p-10"></div>
  </div>
</template>

<script>
import jobs from '../../assets/js/onet_job_zones/job_zones.json';
import careerCluster from '../../assets/js/career_cluster/careerCluster.json';
import brightOutlookOccupations from '../../assets/js/bright_outlook_occupations/brightOutlookOccupations.json';
import occupationsRIASEC from '../../assets/js/filtered_occupation/occupationsRIASEC.json';
import CustomSelect from '../Atoms/CustomSelect.vue';
import bookmarkIcon from '../../assets/images/bookmark.png';
import bookmarkedIcon from '../../assets/images/bookmarked.png';
import CheckBox from '../Atoms/CheckBox.vue';
import {getAssociatedData, saveAssociatedData, updateAssociatedData} from '../../AssociatedData.js';
import {useProfileFilters} from '../../store/profileFilterStates.js';
import {useStudentDemo} from '../../store/StudentDemoStates';
import laborStats from '../../assets/js/labor_stats/BureauLaborStatistics.json'

export default {
  components: {
    CustomSelect,
    CheckBox,
  },
  props: {
    searchKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isBookmarked: [],
      bookmarkIcon,
      savedOccupations: false,
      bookmarkedIcon,
      cluster: [],
      careerClusters: [],
      occupation: [],
      loading: false,
      bestFit: false,
      selectedSalary: "",
      selectedCluster: "",
      selectedEducation: "",
      jobPlaceholder: "Job Zones",
      careerPlaceholder: "Career Cluster",
      salaryPlaceholder: "Salary Range",
      colNames: ["Occupation", "Salary", "Career Cluster", "Pathway", "Job Zones", "Bright Outlook", "Interest Ranking"],
      jobZoneDescriptions: {
        1: "Little or No Preparation Needed",
        2: "Some Preparation Needed",
        3: "Medium Preparation Needed",
        4: "Considerable Preparation Needed",
        5: "Extensive Preparation Needed",
      },
      filteredByBestFit: [],
      scoreFilter1: "",
      scoreFilter2: "",
      scoreFilter3: "",
      path: "",
      typeFormScores: {},
      store: null,
    };
  },
  computed: {
    filteredData() {
      let data = jobs
        .filter((_) => !this.searchKey || _["Title"].toLowerCase().includes(this.searchKey.toLowerCase()))
        .map((_) => {
          const additionalInfo = careerCluster.find((data) => data["Code"] === _["O*NET-SOC Code"]);
          const category = occupationsRIASEC.find((data) => data["Code"] === _["O*NET-SOC Code"]);
          if(category) {
            _ = {..._, "RIASEC Code":category["RIASEC Code"], careerInterest: this.getInterestRanking(category["RIASEC Code"])};
          }
          return additionalInfo ? { ..._, ...additionalInfo } : _;
        })
        .filter((_) => {
          if (!this.selectedEducation || this.selectedEducation === "All") {
            return true;
          }
          return _["Job Zone"] === this.selectedEducation;
        })
        .filter((_) => {
          if (!this.selectedCluster || this.selectedCluster === "All") {
            return true;
          }
          return _["Career Cluster"]?.includes(this.selectedCluster);
        })
        .filter((_) => {
          if (this.bestFit) {
            return this.filteredByBestFit.includes(_["O*NET-SOC Code"]);
          } else {
            return true;
          }
        })
        .filter((_, index) => {
          if (this.savedOccupations) {
            return this.isBookmarked[index];
          } else {
            return true;
          }
        })
      if(this.scoreFilter3) {
        data = data.filter((item) => {
          return item["RIASEC Code"] && item["RIASEC Code"].includes(this.scoreFilter3.charAt(0).toUpperCase());
        }).sort((a, b) => {
          return a['careerInterest'] - b['careerInterest'];
        });
      }
      if(this.scoreFilter2) {
        data = data.filter((item) => {
          return item["RIASEC Code"] && item["RIASEC Code"]?.includes(this.scoreFilter2.charAt(0).toUpperCase());
        }).sort((a, b) => {
          return a['careerInterest'] - b['careerInterest'];
        });
      }
      if(this.scoreFilter1) {
        data = data.filter((item) => {
          return item["RIASEC Code"] && item["RIASEC Code"]?.includes(this.scoreFilter1.charAt(0).toUpperCase());
        }).sort((a, b) => {
          return a['careerInterest'] - b['careerInterest'];
        });
      }
      return data;
    },
    profileFilters() {
      return useProfileFilters();
    },
    typeFormDataExists() {
      return Object.keys(this.typeFormScores).length;
    },
    typeFormScoresWithRank() {
      const sorted = Object.entries(this.typeFormScores).sort((a, b) => b[1] - a[1]);
      return sorted.map((item, index) => {
        return {
          rank: index + 1,
          name: item[0],
          "RIASEC Code": item[0].charAt(0).toUpperCase(),
          score: item[1],
        }
      });
    },
  },
  async mounted() {
    const { $state: state } = this.profileFilters;
    this.store = useStudentDemo();
    this.selectedCluster = state.carrerCluster;
    this.selectedEducation = state.jobZones;
    this.selectedSalary = state.salaryRange;

    this.jobPlaceholder = state.jobZones || "Job Zones";
    this.salaryPlaceholder = state.salaryRange || "Salary Range";
    this.carrerPlaceholder = state.carrerCluster || "Carrer Cluster";
    let uniqueCareerClusters = [
      ...new Set(
        jobs.map((_) => {
          const additionalInfo = careerCluster.find((data) => data["Code"] === _["O*NET-SOC Code"]);
          const output = additionalInfo ? { ..._, ...additionalInfo } : _;
          return output["Career Cluster"];
        })
      ),
    ];

    uniqueCareerClusters = uniqueCareerClusters.filter((item) => item).sort();

    const normalizedData = uniqueCareerClusters.map((item) => item.split(";")[0].trim());
    this.careerClusters = [...new Set(normalizedData)];
    if (!this.store.isStudentDemo) {
      const assocData = await getAssociatedData();

      if (assocData) {
        this.updateBookmarks(assocData?.exploreOccupations);
        if(assocData.typeFormScores) {
          this.typeFormScores = assocData.typeFormScores;
        }
        this.filteredByBestFit = assocData?.jobCodes || [];
        this.cluster = assocData.cluster;
        await this.fetchONET();
      }
    }
    this.loading = false;
  },

  beforeUnmount() {
    if (this.path.includes("/occupation/")) {
      this.profileFilters.setOccupationFilters({
        selectedCluster: this.selectedCluster,
        selectedEducation: this.selectedEducation,
        selectedSalary: this.selectedSalary,
      });
    } else {
      this.profileFilters.resetOccupationFilters();
      this.jobPlaceholder = "Job Zones";
      this.carrerPlaceholder = "Carrer Cluster";
      this.salaryPlaceholder = "Salary Range";
    }
  },
  methods: {
    reset() {
      this.profileFilters.resetOccupationFilters();
      this.selectedSalary = "";
      this.selectedCluster = "";
      this.selectedEducation = "";
      (this.jobPlaceholder = "Job Zones"),
        (this.carrerPlaceholder = "Carrer Cluster"),
        (this.salaryPlaceholder = "Salary Range"),
        this.$refs.carrerSelect.reset();
      this.$refs.salarySelect.reset();
      this.$refs.jobSelect.reset();
    },
    navigateTo(path) {
      this.path = path;
      this.$router.push(path);
    },

    async fetchONET() {
      try {
        const cluster = this.cluster && this.cluster.length > 0 ? this.cluster[0] : "Finance";
        const response = await this.$request(`/v1/getONETData/${cluster}`);
        this.occupation = response.data.occupation;
        return response;
      } catch (error) {
        throw error;
      }
    },
    truncate(text, length) {
      if (text && typeof text === "string" && text.length > length) {
        return text.substring(0, length) + "...";
      }
      return text || "";
    },
    isCodePresent(code) {
      return brightOutlookOccupations.some((occupation) => occupation["Code"] === code);
    },
    handleSelect(type, value) {
      if (type === "salary") {
        this.selectedSalary = value;
      } else if (type === "cluster") {
        this.selectedCluster = value;
      } else if (type === "education") {
        this.selectedEducation = value;
      }
    },
    getJobZoneDescription(zone) {
      return this.jobZoneDescriptions[zone] || "Unknown Job Zone";
    },
    async changeIcon(index, occupation) {

      if (this.store.isStudentDemo) {
        this.isBookmarked[index] = !this.isBookmarked[index];
      } else {
        const ad = await getAssociatedData();

        if (ad != null) {
          let isSaved =
            ad.exploreOccupations != null &&
            ad.exploreOccupations.length > 0 &&
            ad.exploreOccupations?.some(
              (exploreOccupation) =>
                exploreOccupation.title === occupation.Title &&
                exploreOccupation["Career Cluster"] === occupation.careerCluster &&
                exploreOccupation["Career Pathway"] === occupation.Pathway
            );

          if (isSaved) {
            ad.exploreOccupations = ad.exploreOccupations.filter(
              (exploreOccupation) =>
                exploreOccupation.title !== occupation.Title &&
                exploreOccupation["Career Cluster"] !== occupation.careerCluster &&
                exploreOccupation["Career Pathway"] !== occupation.Pathway
            );
          } else {
            ad.exploreOccupations != null && ad.exploreOccupations.length > 0
              ? ad.exploreOccupations.push({
                title: occupation.Title,
                Salary: 0,
                CareerCluster: occupation["Career Cluster"],
                Pathway: occupation["Career Pathway"],
                JobZone: occupation["Job Zone"],
                BrightOutlook: this.isCodePresent(occupation["O*NET-SOC Code"]),
                saved: true,
              })
              : (ad.exploreOccupations = [
                {
                  title: occupation.Title,
                  Salary: 0,
                  CareerCluster: occupation["Career Cluster"],
                  Pathway: occupation["Career Pathway"],
                  JobZone: occupation["Job Zone"],
                  BrightOutlook: this.isCodePresent(occupation["O*NET-SOC Code"]),
                  saved: true,
                },
              ]);
          }
          const res = await updateAssociatedData(ad);

          if (res?.status === 200 && isSaved) {
            this.isBookmarked[index] = false;
          }

          if (res?.status === 200 && !isSaved) {
            this.isBookmarked[index] = true;
          }
        } else {
          const ad = {};
          ad.exploreOccupations = [
            {
              title: occupation.Title,
              Salary: 0,
              CareerCluster: occupation["Career Cluster"],
              Pathway: occupation["Career Pathway"],
              JobZone: occupation["Job Zone"],
              BrightOutlook: this.isCodePresent(occupation["O*NET-SOC Code"]),
              saved: true,
            },
          ];
          const res = await saveAssociatedData(ad);

          if (res?.status === 200) {
            this.isBookmarked[index] = true;
          }
        }
      }
    },

    updateBookmarks(asd = []) {
      this.isBookmarked = this.filteredData.map((occupation) => {
        return asd?.some(
          (data) =>
            data.title === occupation.Title &&
            data["Career Cluster"] === occupation.careerCluster &&
            data["Career Pathway"] === occupation.Pathway
        );
      });
    },
    showSavedOccupation() {
      this.savedOccupations = !this.savedOccupations;
    },
    getInterestRanking(code) {
      if(this.typeFormDataExists && code) {
        const filtered = this.typeFormScoresWithRank.filter((item) => code.includes(item["RIASEC Code"]));
        if(filtered?.length) {
          return filtered[0].rank;
        }
      }
      return null;
    },
    getSalary(code){
      return laborStats.find(el => code.toString().includes(el["OCC_CODE"]))
    }
  },
};
</script>

<style scoped>
@import url("./table.css");

.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip::after {
  content: "\2139 " attr(data-tooltip);
  /* Unicode character for info icon */
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateX(-50%);
  background-color: #259b3521;
  /* Green with 80% opacity */
  color: #475f4a;
  padding: 5px 10px;
  border-radius: 4px;
  border: 0.5px solid black;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  z-index: 1;
  display: flex;
  align-items: center;
}

.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}
</style>
